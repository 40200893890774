import React, { useEffect, useState, useRef } from 'react';
// import { useLocation } from 'react-router-dom';
import styles from './MLPanel.mod.scss';
import useBluetooth from '@utils/hooks/bluetooth'
import useMachineLearning from '@utils/hooks/machineLearning'
import LinearProgress from '@mui/material/LinearProgress';
import { MODEL_TYPES, TRAIN_STEPS } from '@utils/hooks/machineLearning/params';

const ARDUINO_CONNECTION_SERVICE = '0000ffe0-0000-1000-8000-00805f9b34fb'
const ARDUINO_CONNECTION_CHARACTERISTIC = '0000ffe1-0000-1000-8000-00805f9b34fb'

const MLPanel = React.memo((props) => {
  // const location = useLocation();
  const DebounceState = useRef(0)
  const lastSentCommand = useRef('')
  const mlDataSet = useRef([])

  const [commandClass, setCommandClass] = useState('MLClass')
  const [stopCommandClass, setStopCommandClass] = useState('MLC_Stop')
  const [videoWrapper, setVideoWrapper] = useState('canvas-wrapper')
  const [confidenceLevel, setConfidenceLevel] = useState(70)
  const [messageDelay, setMessageDelay] = useState(300)
  const [defaultClassCount, setDefaultClassCount] = useState(5)

  const [step, setStep] = useState(TRAIN_STEPS.TEACH)
  const [cameraAllowed, setCameraAllowed] = useState(false)
  const [samples, setSamples] = useState({})
  const [confidences, setConfidences] = useState({})
  const [toggleVideo, setToggleVideo] = useState(true)
  const [importedDataSet, setImportedDataSet] = useState(null)
  const [loading, setLoading] = useState(null)
  const [modelType, setModelType] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null);
  const [modelTrained, setModelTrained] = useState(false);
  const [modelImported, setModelImported] = useState(false);
  const [trainingParams, setTrainingParams] = useState({
    epochs: 0,
    learningRate: 0,
    batchSize: 0,
  });
  const [trainingData, setTrainingData] = useState({
    epoch: 0,
    acc: 0,
    loss: 0,
    val_acc: 0,
    val_loss: 0,
  });
  const [importedJsonFile, setImportedJsonFile] = useState(null);
  const [importedBinFile, setImportedBinFile] = useState(null);
  const [hasBluetooth, setHasBluetooth] = useState(props.board?.bluetooth || false);



  const { requestConnection, sendMessage, connected } = useBluetooth({
    serviceID: ARDUINO_CONNECTION_SERVICE,
    characteristicID: ARDUINO_CONNECTION_CHARACTERISTIC,
  });

  const getPrediction = (detectionConfidences) => {
    setConfidences(detectionConfidences);
    let classDetected = false;
    if (Object.keys(detectionConfidences).length > 0) {
      mlDataSet.current.map((data) => {
        if (detectionConfidences[data[0]] && (detectionConfidences[data[0]] || 0) * 100 >= confidenceLevel) {
          classDetected = true;
          if (Date.now() - DebounceState.current > messageDelay) {
            DebounceState.current = Date.now();
            console.log('================message', data[1]);
            if (hasBluetooth) {
              sendMessage(data[1]);
            }
            lastSentCommand.current = data[1];
          }
        }
      });
    }
    if (!classDetected && lastSentCommand.current !== stopCommandClass) {
      console.log('================message', stopCommandClass);
      if (hasBluetooth) {
        sendMessage(stopCommandClass);
      }
      lastSentCommand.current = stopCommandClass;
    }
  }
  const {
    init,
    getDefaultTrainingParams,
    stopVideo,
    startVideo,
    addSample,
    clearAllSamples,
    startClassifying,
    stopClassifying,
    // addDataset,
    train,
    exportModel,
    importModel,
  } = useMachineLearning({
    trainingParams,
    wrapperClass: videoWrapper,
    getPredictionCallback: getPrediction,
  });

  const onToggleChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  }

  const changeStep = (newStep) => {
    setStep(newStep);
    if (newStep === TRAIN_STEPS.CLASSIFY) {
      if (!connected && hasBluetooth) {
        // TODO: 'requestDevice' on 'Bluetooth': Must be handling a user gesture to show a permission request
        requestConnection()
      }
      if (!toggleVideo) {
        startVideo();
        setToggleVideo(true);
      }
      startClassifying();
    } else if (newStep === TRAIN_STEPS.TRAIN) {
      stopVideo();
      setToggleVideo(false);
      setModelTrained(false);
      setModelImported(false);
      stopClassifying();
    } else if (newStep === TRAIN_STEPS.TEACH) {
      clearAllSamples();
      setSamples({});
      setTrainingData({
        epoch: 0,
        acc: 0,
        loss: 0,
        val_acc: 0,
        val_loss: 0,
      });
      setModelTrained(false);
      stopClassifying();
      if (modelImported) {
        setDefaultClasses();
        setModelImported(false);
      }
      if (!toggleVideo) {
        startVideo();
        setToggleVideo(true);
      }
    }
  }

  const takeSample = (className) => {
    const nSamples = { ...samples };
    const count = addSample(className);
    nSamples[className] = count;
    setSamples(nSamples);
  }

  const renderClassContainers = () => {
    const containers = [];
    mlDataSet.current.map((data) => {
      containers.push(
        <div key={data[1]} className={styles.classContainer}>
          <div className={styles.label}>
            <label>{data[0]}</label>
          </div>
          <button onClick={() => takeSample(data[0])}>Tomar muestra ({samples[data[0]] || 0})</button>
        </div>
      );
    });
    return containers;
  };

  const renderPredictionContainers = () => {
    
    const containers = [];
    mlDataSet.current.map((data) => {
      containers.push(
        <div key={data[1]} className={`${styles.classContainer} ${styles.predictionContainer}`}>
          <div className={styles.label}>
            <label>{data[0]}</label>
            <span>{((confidences[data[0]] || 0) * 100).toFixed(2) || 0} %</span>
          </div>
          <LinearProgress className={styles.progress} variant="determinate" value={parseFloat((confidences[data[0]] || 0) * 100)} />
        </div>
      );
    });
    return containers;
  };

  const handleToggleVideo = () => {
    setToggleVideo(!toggleVideo);
    if (toggleVideo) {
      stopVideo();
    } else {
      startVideo()
        .then(() => {
          startDataSet();
        })
        .catch((e) => {
          console.error('=============', e);
          setCameraAllowed(false);
          setToggleVideo(false);
        });
    }
  }

  const handleAskCameraPermissions = () => {
    startVideo().then(() => {
      setCameraAllowed(true);
      setToggleVideo(true);
    })
      .catch((e) => {
        console.error('=============', e);
        setCameraAllowed(false);
      });
  }

  const startDataSet = async () => {
    // if (importedDataSet) {
    //   mlDataSet.current = [];
    //   const nSamples = {};
    //   const classes = Object.keys(importedDataSet);
    //   for (let i = 0; i < classes.length; i++) {
    //     mlDataSet.current.push([classes[i], `${commandClass}${i + 1}`]);
    //     nSamples[classes[i]] = importedDataSet[classes[i]].length;
    //   }
    //   setSamples(nSamples);
    //   await addDataset(importedDataSet);
    //   changeStep(TRAIN_STEPS.CLASSIFY);
    // }
    setCameraAllowed(true);
    setToggleVideo(true);
  };

  const handleTrain = async () => {
    setLoading('Preparando modelo ...');
    await train({
      onEpochBegin: async (epoch, logs) => {
        // console.log("Epoch: ", epoch);
      },
      onEpochEnd: async (epoch, log) => {
        setTrainingData({
          epoch,
          acc: log.acc || 0,
          loss: log.loss || 0,
          val_acc: log.val_acc || 0,
          val_loss: log.val_loss || 0,
        });
        if (epoch === trainingParams.epochs - 1) {
          setModelTrained(true);
        }
      },
    });
    setLoading(null);
  };

  const setDefaultClasses = () => {
    mlDataSet.current = [];
    for (let i = 1; i <= defaultClassCount; i++) {
      mlDataSet.current.push([`Clase ${i}`, `${commandClass}${i}`]);
    }
  }

  const handleOnChangeFile = (event) => {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].name.endsWith('.json')) {
        setImportedJsonFile(files[i]);
      } else if (files[i].name.endsWith('.bin')) {
        setImportedBinFile(files[i]);
      }
    }
  }
  const handleImportModel = async () => {
    setLoading('Importando modelo ...');
    // const importedClasses = await importModel(document.getElementById('file1'), document.getElementById('file2'));
    const importedClasses = await importModel(importedJsonFile, importedBinFile);
    setLoading(null);
    if (importedClasses) {
      mlDataSet.current = [];
      const classes = Object.keys(importedClasses);
      for (let i = 0; i < classes.length; i++) {
        mlDataSet.current.push([classes[i], `${commandClass}${i + 1}`]);
      }
      setModelImported(true);
      changeStep(TRAIN_STEPS.CLASSIFY);
    }
  }

  useEffect(async () => {
    if (importedJsonFile && importedBinFile) {
      await handleImportModel();
    }
  }, [importedJsonFile, importedBinFile])

  useEffect(() => {
    if (modelType) {
      setLoading('Preparando la cámara ...');
      init(modelType)
        .then(() => {
          setTrainingParams(getDefaultTrainingParams());
          startDataSet();
          setLoading(null);
          setErrorMessage(null);
        })
        .catch((e) => {
          console.error('=============', e);
          setErrorMessage('No has dado permisos para que usemos la cámara. Por favor revisa en la barra de direcciones que no esté bloqueada.');
          setCameraAllowed(false);
          setLoading(null);
        });
    }

    return () => {
      stopVideo();
      setLoading(null);
      setToggleVideo(false);
    }
  }, [props.active, importedDataSet, modelType])

  useEffect(() => {
    if (props.workspace) {
      const classes = {};
      mlDataSet.current.map((data) => {
        classes[data[1]] = data[0];
      });
      classes[stopCommandClass] = 'No reconocido';
      props.workspace.setMLClasses(classes);
    }
  }, [props.workspace, mlDataSet.current])
  
  useEffect(() => {
    setImportedDataSet(props.iaDataSet || '');
    setModelType(props.iaModel || MODEL_TYPES.HANDS);
  }, [props.iaModel, props.iaDataSet])

  useEffect(() => {
    // const searchParams = new URLSearchParams(location.search);
    // const dataSet = searchParams.get('dataset_id') || '';
    // setImportedDataSet(dataSet);
    // const model = searchParams.get('model') || MODEL_TYPES.HANDS;
    // setModelType(model);
    setDefaultClasses();
  }, [])

  return (
    <React.Fragment>
      <div className={`col-12 p-0 codeBox ${(props.active) ? 'codeBox-active' : ''} ${styles.codeMLBox} ${(props.className || '')}`}>
        <div className={styles.contentCodeBox}>
          {loading ? (
            <div className={styles.loading}>{loading}</div>
          ) : (null)}
          {step !== TRAIN_STEPS.TRAIN ? (
            <div class={videoWrapper}>
              <canvas id="output"></canvas>
              <video id="video" playsinline style={{
                transform: 'scaleX(-1)',
                visibility: 'hidden',
                display: 'none',
                width: 'auto',
                height: 'auto',
              }}>
              </video>
            </div>
          ) : (null)}
          {step === TRAIN_STEPS.TEACH ? (
            <>
              {renderClassContainers()}
              <div className={styles.teachActions}>
                {Object.values(samples).filter(sample => sample > 0).length > 1 ? (
                  <button onClick={() => changeStep(TRAIN_STEPS.TRAIN)}><img src={`${process.env.IS_ELECTRON ? '.' : ''}/images/ai/play.svg`} />Preparar modelo</button>
                ) : (null)}
                {!cameraAllowed ? (
                  <button onClick={handleAskCameraPermissions}>Permitir</button>
                ) : (
                  <button className={styles.cancel} onClick={handleToggleVideo}>{toggleVideo ? 'Apagar' : 'Encender'} cámara</button>
                )}
                <div className={styles.importContainer}>
                  <div><strong>Importar modelo descargado:</strong></div>
                  <div><label>Archivos (.json, .bin):</label><input type="file" id="importedFiles" accept=".json,.bin" multiple onChange={handleOnChangeFile} /></div>
                  {/* <div><label>JSON:</label><input type="file" id="file1" accept=".json" onChange={handleOnChangeFile} /></div>
                  <div><label>Pesos:</label><input type="file" id="file2" accept=".bin" onChange={handleOnChangeFile} /></div>
                  <button onClick={handleImportModel}>Importar modelo</button> */}
                </div>
                <div className={`${styles.error} ${(errorMessage) ? styles.visible : ''}`}>{errorMessage}</div>
              </div>
            </>
          ) : (null)}
          {step === TRAIN_STEPS.TRAIN ? (
            <>
              <div className={styles.trainActions}>
                <div className={styles.trainParams}>
                  <div><strong>Parámetros de entrenamiento:</strong></div>
                  <div><label>Épocas:</label><input type="number" value={trainingParams.epochs} onChange={(e) => setTrainingParams({ ...trainingParams, epochs: e.target.value })} /></div>
                  <div><label>Tasa de aprendizaje:</label><input type="number" value={trainingParams.learningRate} onChange={(e) => setTrainingParams({ ...trainingParams, learningRate: e.target.value })} /></div>
                  <div><label>Tamaño de lote:</label><input type="number" value={trainingParams.batchSize} onChange={(e) => setTrainingParams({ ...trainingParams, batchSize: e.target.value })} /></div>
                </div>
                {trainingData.epoch ? (
                  <div className={styles.trainResults}>
                    <div><strong>Resultados:</strong></div>
                    <div>Época: <strong>{trainingData.epoch}</strong></div>
                    <div>Presición: <strong>{trainingData.acc.toFixed(4)}</strong></div>
                    <div>Presición de test: <strong>{trainingData.val_acc.toFixed(4)}</strong></div>
                    <div>Pérdida: <strong>{trainingData.loss.toFixed(4)}</strong></div>
                    <div>Pérdida de test: <strong>{trainingData.val_loss.toFixed(4)}</strong></div>
                  </div>
                ) : (null)}
                <button onClick={() => handleTrain()}>Preparar modelo</button>
                {modelTrained ? (
                  <>
                    <button onClick={() => changeStep(TRAIN_STEPS.CLASSIFY)}>Iniciar análisis</button>
                    <button onClick={() => exportModel()}>Descargar modelo</button>
                  </>
                ) : (null)}
                <button className={styles.cancel} onClick={() => changeStep(TRAIN_STEPS.TEACH)}>Volver a entrenar</button>
                <div className={`${styles.error} ${(errorMessage) ? styles.visible : ''}`}>{errorMessage}</div>
              </div>
            </>
          ) : (null)}
          {step === TRAIN_STEPS.CLASSIFY ? (
            <>
              {renderPredictionContainers()}
              <div className={styles.classifyActions}>
                {!importedDataSet && !modelImported ? (
                  <>
                    <button onClick={() => changeStep(TRAIN_STEPS.TRAIN)}>Ajustar modelo</button>
                    <button onClick={() => exportModel()}>Descargar modelo</button>
                  </>
                ) : (null)}
                {!importedDataSet || modelImported ? (
                  <button className={styles.cancel} onClick={() => changeStep(TRAIN_STEPS.TEACH)}>Volver a entrenar</button>
                ) : (null)}
                {!connected && hasBluetooth ? (
                  <button className={styles.cancel} onClick={requestConnection}>Conectar bluetooth</button>
                ) : (null)}
                <button className={styles.cancel} onClick={handleToggleVideo}>{toggleVideo ? 'Apagar' : 'Encender'} cámara</button>
                <div className={`${styles.error} ${(errorMessage) ? styles.visible : ''}`}>{errorMessage}</div>
              </div>
            </>
          ) : (null)}
        </div>
        <div className="toggle-codeBox toggle-MLPanel" role="button" tabIndex="0" onClick={onToggleChange}>
          <span className="curly-braces"><img src={`${process.env.IS_ELECTRON ? '.' : ''}/images/ai/toggleAiPanel.svg`} /></span>
          <span className={`icon-chevron-left ${(props.active) ? 'rotate-arrow' : ''}`} />
        </div>
      </div>
    </React.Fragment >
  );
});

export default MLPanel;