
export function getLabsUrl() {
  switch (true) {
    case process.env.NODE_ENV === 'production':
      return 'https://labs.educabot.com';
    case process.env.NODE_ENV === 'staging':
      return 'https://labs.staging.educabot.com';
    case process.env.NODE_ENV === 'development':
      return 'http://localhost:9002';
    default:
      return 'https://labs.educabot.com';
  }
}
